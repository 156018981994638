import React, { useState } from "react"
import { Link, useStaticQuery, graphql, navigate } from "gatsby"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import Image from "../../../components/image"
import ImageForTwitter from "../../../components/imageForTwitter"
import Review from "../../../components/review"
import Share from "../../../components/share"
import TweetWidget from "../../../components/tweetWidget"
import moment from 'moment'
import Campaign from "../../../components/campaign"
import Tag from "../../../components/tag"
import { FaChevronDown } from "react-icons/fa"
import { ShopName, ShopNameSelected, OldMessage, ImageFilename} from '../../../utils/common'
import { IoListCircleOutline } from "react-icons/io5";
import { IoMdRadioButtonOn } from "react-icons/io";
import { IoMdRadioButtonOff } from "react-icons/io";
import { FaCheckSquare } from "react-icons/fa";
import { FaRegSquare } from "react-icons/fa";
import { XIcon} from "react-share";

const ListSearchAllDrinkResult = props  => {
  // itemListからtxtがあるものだけを抽出し、allContentfulItems形で格納する。itemList[0]にばらす感じ。
  var allDiscountItems = {}
  allDiscountItems.edges = []

  for (var items of props.tempData.allContentfulItems.edges)
  {
    for (var item of items.node.itemList){
      var discountItem = {}
      discountItem.node = {
        brandId:items.node.brandId,
        sizeId:items.node.sizeId,
        subbrandId:items.node.subbrandId,
        typeId:items.node.typeId,
      }
      discountItem.node.itemList = []
      discountItem.node.itemList.push(item)
      allDiscountItems.edges.push(discountItem)
    }
  }

  // allDiscountItems.edgesを100ml当たりの価格が安い順に並び替える
  allDiscountItems.edges.sort(function(a,b){
    return a.node.itemList[0].per - b.node.itemList[0].per
  })
  const _filterItems = allDiscountItems.edges.filter(function(tempItem){
    if((props.brandValue === 'all') && (props.shopValue === 'all')){
      return (tempItem.node.typeId === props.typeValue) && (tempItem.node.sizeId === props.sizeValue)
    }
    else if((props.brandValue === 'all') && (props.shopValue !== 'all')){
      const _shopValue = props.shopValue === 'other' ? 'babiesrus' : props.shopValue;
      return (tempItem.node.typeId === props.typeValue) && (tempItem.node.sizeId === props.sizeValue) && (tempItem.node.itemList[0].shopId === _shopValue)
    }
    else if((props.brandValue !== 'all') && (props.shopValue === 'all')){
      return (tempItem.node.typeId === props.typeValue) && (tempItem.node.sizeId === props.sizeValue) && (tempItem.node.brandId === props.brandValue)
    }
    else if((props.brandValue !== 'all') && (props.shopValue !== 'all')){
      const _shopValue = props.shopValue === 'other' ? 'babiesrus' : props.shopValue;
      return (tempItem.node.typeId === props.typeValue) && (tempItem.node.sizeId === props.sizeValue) && (tempItem.node.brandId === props.brandValue) && (tempItem.node.itemList[0].shopId === _shopValue)
    }
    else{
      // ここは通らない
      return true
    }
  })
  const filterItems = _filterItems.filter(function(tempItem){
    if(props.optionValue === ''){
      return true
    }
    else if(props.optionValue === '2'){
      return ((!tempItem.node.itemList[0].txt.includes('らくベビ300')) && (!tempItem.node.itemList[0].txt.includes('らくベビ600')))
    }
    else if(props.optionValue === '3'){
      return (!tempItem.node.itemList[0].txt.includes('GOONPLUS2441'))
    }
    else if(props.optionValue === '4'){
      return ((!tempItem.node.itemList[0].txt.includes('らくベビ300')) && (!tempItem.node.itemList[0].txt.includes('らくベビ600')) && (!tempItem.node.itemList[0].txt.includes('GOONPLUS2441')))
    }
    else{
      // ここは通らない
      return true
    }
  })
  const result = filterItems.slice(0,10)

  let cnt = 0
  let cheap_item0 = {}
  return (
    <div className="columns is-multiline is-gapless">
      {result.map(item => {
        const type = getType(props.tempData, item.node.typeId)
        const size = getSize(props.tempData, item.node.sizeId)
        const brand = getBrand(props.tempData, item.node.brandId)
        const subbrand = getSubbrand(props.tempData, item.node.brandId, item.node.subbrandId)
        const filename = `${brand.id}_${type.id}_${size.id}_${subbrand.subbrand_id}.jpg`
        const detailurl = `/drink/${type.id}_${size.id}_${brand.id}_${subbrand.subbrand_id}.html`
        const sizeStr = '' 
        const itemStr = 'ドリンク'
        const item0 = item.node.itemList[0]
        const subbrandDetail = subbrand.types.find(n => {
          return n.type_id === type.id
        }).sizes.find(n => {
          return n.size_id === size.id
        })
        const pack = item0.num/subbrandDetail.num
        const num = `${subbrandDetail.num}ml×${pack}=${item0.num}ml`

        const sendPrice = item0.sendPrice > 0 ? `送料${item0.sendPrice}円` : item0.sendPrice === 0 ? `送料無料` : item0.sendPrice === -1 ? `実店舗` : '店舗受取';
        cnt += 1
        if(cnt === 1){
          cheap_item0 = item0
        }
        const order = cnt
        let price_org = ``
        if(item0.txt){
          if(item0.txt.match(/OFF/))
          {
            price_org = `${PriceKanma(item0.price_org)}円`
          }
        }
        return (
          <div className="column is-half">
            <a href={`${item0.href}`} rel="nofollow"
              onClick={e => {
                trackCustomEvent({
                  category: `${type.id}_${size.id}_${brand.id}_${subbrand.subbrand_id}`,
                  action: `${item0.shop}`,
                  label: `TopPage${order}位`,
                  transport: 'beacon'
                })
              }}									 
            >
              <div className="card">
                <div className="card-content" style={{paddingTop: `0.8rem`, paddingBottom: `0.8rem`, paddingLeft: `0.5rem`, paddingRight: `0.5rem`,}}>
                  <div className="media">
                    <div className="media-left" style={{margin: `0.0rem`}}>
                      { cnt !== 0 && (
                        <span><strong>&nbsp;&nbsp;&nbsp;&nbsp;{order}<span style={{fontSize:`0.9rem`}}>位</span></strong></span>
			                )}
                      <Image filename={filename} alt={`${brand.description}${subbrand.description}${type.description}${size.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
                      { cnt === 1 && (
                        <ImageForTwitter filename={filename} alt={`${brand.description}${subbrand.description}${type.description}タイプ${size.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
			                )}
                      { cnt === 1 && (
					              <span class="has-text-danger" style={{fontSize:`0.8rem`}}>&nbsp;&nbsp;&nbsp;<strong>最安値</strong></span>
			                )}
                      { cnt === 1 && (
                        <div class="has-text-centered" style={{paddingTop:`0.95rem`, fontSize: `32px`}}>
                        <Link to={detailurl}><span className="icon"><IoListCircleOutline style={{color: `#EB9DA8`}} /></span></Link>
                        </div>
			                )}
                      { cnt !== 1 && (
                        <div class="has-text-centered" style={{paddingTop:`0.72rem`, fontSize: `32px`}}>
                        <Link to={detailurl}><span className="icon"><IoListCircleOutline style={{color: `#EB9DA8`}} /></span></Link>
                        </div>
			                )}
                    </div>
                    <div className="media-content">
                      <div className="content">
                        <p style={{marginBottom: `0.2rem`}}>
                          <span style={{fontSize:`0.7rem`}}><strong>{brand.description} {subbrand.description}</strong></span>
                          <br />
                          <span style={{color: `#ff0000`}} className="is-size-4">&nbsp;{item0.per.toFixed(1)}<span style={{fontSize:`0.7rem`}}> 円/100ml</span></span>
                          <span style={{color: `#ff0000`}} className="is-size-7">&nbsp;&nbsp;{PriceKanma(item0.price)}円<span style={{color: `#808080`}}>&nbsp;<strike>{price_org}</strike></span></span>&nbsp;
                          <Tag item={item0} />
                          <br />
                          { 'point' in item0 && item0.point > 0 && (
                            <><span className="tag is-danger is-light" style={{fontSize:`0.6rem`}}><b>{item0.point}ポイント</b></span>&nbsp;</>
                          )}
                          <span className="tag" style={{fontSize:`0.6rem`}}>{sendPrice}</span>&nbsp;
                          <span className="tag" style={{fontSize:`0.6rem`}}>{num}</span>&nbsp;
                          <br />
                          <span style={{color: `#0783c2`}} className="is-size-7">&nbsp;&nbsp;{item0.shop}</span>
                          <Review item={item0} category={`${type.id}_${size.id}_${brand.id}_${subbrand.subbrand_id}`}/>
                        </p>
                        <Campaign item={item0} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            { cnt === 1 && (
              <TweetWidget urlPath={`${props.articleUrl}`} />				
            )}
            { cnt === 10 && (
              <Share articleUrl={`${props.articleUrl}`} articleTitle={`【${cheap_item0.per.toFixed(1)}円/100ml】${props.articleTitle}`} />					
            )}
          </div>
        )
      })}
    </div>
  )
}

const ListSearchAllDrink = props => {
	// 更新日付をGraphQLから直接表示するとUTCとなるため、自前のmoment.jsで表示
	const update = moment().format('YYYY年M月D日')
  const tempData  = useStaticQuery(graphql`
    query ListSearchAllDrinkQuery {
      allContentfulItems {
        edges {
          node {
            brandId
            sizeId
            subbrandId
            typeId
            itemList {
              num
              href
              shopId
              shop
              per
              per_org
              price
              price_org
              sendPrice
              souryou
              txt
              point
              star
              review
              reviewUrl
            }
          }
        }
      }
      allTypesDrinkYaml {
        edges {
          node {
            id
            description
          }
        }
      }
      allSizesDrinkYaml {
        edges {
          node {
            id
            description
          }
        }
      }
      allBrandsDrinkYaml {
        edges {
          node {
            id
            description
          }
        }
      }
      allShopsYaml {
        edges {
          node {
            id
            description
          }
        }
      }
      allSubbrandsDrinkYaml {
        edges {
          node {
            brand_id
            subbrand_id
            description
            types {
              type_id 
              sizes {
                size_id
                num	
              }
            }
          }
        }
      }  
    }
  `)

  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState(props.shopValue);

  const toggleDropdown = () => { setIsActive(!isActive);};

  const handleItemClick = (shop_id) => {
    setSelected(shop_id);
    setIsActive(false);
    const url = ShopLink(props.tempData, props, shop_id)
    navigate(url);
  }

  return (
      <>
        <h2 className="title is-6" id="discount" style={{marginTop: `0.5rem`, marginLeft: `0.5rem`, marginBottom: `0.5rem`}}>ドリンク ランキング Top10<small> ({update}更新)</small></h2>
        <p style={{fontSize:`0.8rem`, color: `#808080`}}><small>&nbsp;&nbsp;&nbsp;<strong>割引後にポイントと送料を含めた実質価格で</strong>100mlあたりを計算！</small></p>
				<p style={{fontSize:`0.8rem`, color: `#808080`, marginBottom: `0.5rem`}}><small>&nbsp;&nbsp;&nbsp;（本ページのリンクには広告が含まれています）</small></p>
        <div class="tabs is-toggle is-small is-fullwidth" style={{marginBottom: `0.1rem`, marginLeft: `0.1rem`, marginRight: `0.1rem`, fontSize:`0.5rem`}}>
          <li class={TypeActive(props, 'baby_tea')}><Link to={TypeLink(tempData, props, 'baby_tea')}>お茶(ベビー)</Link></li>
          <li class={TypeActive(props, 'baby_ion')}><Link to={TypeLink(tempData, props, 'baby_ion')}>イオン飲料</Link></li>
          <li class={TypeActive(props, 'tea')}><Link to={TypeLink(tempData, props, 'tea')}>　お茶　</Link></li>
          <li class={TypeActive(props, 'natural_water')}><Link to={TypeLink(tempData, props, 'natural_water')}>天然水</Link></li>
        </div>
        <div class="tabs is-toggle is-small is-fullwidth" style={{marginBottom: `0.1rem`, marginLeft: `0.1rem`, marginRight: `0.1rem`,fontSize:`0.5rem`}}>
          <ul>
            { SizeExist(tempData, props.typeValue, 'pet') && (
              <li class={SizeActive(props, 'pet')}><Link to={SizeLink(tempData, props, 'pet')}>ペットボトル</Link></li>
            )}
          </ul>
        </div>
        <div class="tabs is-toggle is-small is-fullwidth" style={{marginBottom: `0.1rem`, marginLeft: `0.1rem`, marginRight: `0.1rem`,fontSize:`0.5rem`}}>
          <ul>
            <li class={BrandActive(props, 'all')}><Link to={BrandLink(tempData, props, 'all')}>すべて</Link></li>
            { BrandExist(tempData, props.typeValue, props.sizeValue, 'wakodo') && (
              <li class={BrandActive(props, 'wakodo')}><Link to={BrandLink(tempData, props, 'wakodo')}>和光堂</Link></li>
            )}
            { BrandExist(tempData, props.typeValue, props.sizeValue, 'cocacola') && (
              <li class={BrandActive(props, 'cocacola')}><Link to={BrandLink(tempData, props, 'cocacola')}>コカ・コーラ</Link></li>
              )}
            { BrandExist(tempData, props.typeValue, props.sizeValue, 'asahi') && (
              <li class={BrandActive(props, 'asahi')}><Link to={BrandLink(tempData, props, 'asahi')}>アサヒ</Link></li>
              )}
          </ul>
        </div>
        <div class="container" style={{marginRight: `0.0rem`}}>
          <div class="columns is-mobile">
            <div class="column is-5" style={{paddingRight: `0.0rem`, paddingTop: `0.8rem`, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
            { (props.shopValue === 'all' || props.shopValue === 'amazon') && (
              <>
              { props.optionValue === '' && (
                <p>
                {/* <Link to={`/drink/${props.typeValue}_${props.sizeValue}_${props.brandValue}_${props.shopValue}2`}><span className="icon"><FaRegSquare style={{color: `#EB9DA8`}} /></span><span style={{fontSize:`0.7rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>らくベビ使用済</span></Link><br /> */}
                </p>
              )}
              { props.optionValue === '2' && (
                <p>
                {/* <Link to={`/drink/${props.typeValue}_${props.sizeValue}_${props.brandValue}_${props.shopValue}`}><span className="icon"><FaCheckSquare style={{color: `#EB9DA8`}} /></span><span style={{fontSize:`0.7rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>らくベビ使用済</strong></span></Link><br /> */}
                </p>
              )}
              { props.optionValue === '3' && (
                <p>
                {/* <Link to={`/drink/${props.typeValue}_${props.sizeValue}_${props.brandValue}_${props.shopValue}4`}><span className="icon"><FaRegSquare style={{color: `#EB9DA8`}} /></span><span style={{fontSize:`0.7rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>らくベビ使用済</span></Link><br /> */}
                </p>
              )}
              { props.optionValue === '4' && (
                <p>
                {/* <Link to={`/drink/${props.typeValue}_${props.sizeValue}_${props.brandValue}_${props.shopValue}3`}><span className="icon"><FaCheckSquare style={{color: `#EB9DA8`}} /></span><span style={{fontSize:`0.7rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>らくベビ使用済</strong></span></Link><br /> */}
                </p>
              )}
              </>
            )}
            </div>
            <div class="column is-4" style={{paddingLeft: `0.0rem`, paddingBottom: `0.9rem`}}>
              <div className="has-text-centered" style={{paddingTop: `0.3rem`, paddingBottom: `0.0rem`}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <a href="https://x.com/hula_la_" target='_blank'><XIcon size={18} round /></a>
                <p style={{fontSize:`0.7rem`, marginTop: `-0.3rem`}}><a href="https://x.com/hula_la_" target='_blank' style={{color: `#0783c2`}}>&nbsp;&nbsp;\\ 更新ポスト //</a></p>
                </div>
              </div>              
            </div>
            <div class="column is-3" style={{paddingBottom: `0.9rem`}}>
              <div class="is-flex is-justify-content-flex-end"style={{marginBottom: `0rem`}}>
                <div className={`dropdown ${isActive ? 'is-active' : ''}`}>
                  <div class="dropdown-trigger">
                    <button onClick={toggleDropdown} className="button" aria-haspopup="true" aria-controls="dropdown-menu" style={{fontSize:`0.6rem`}}>
                      <span>{ShopNameSelected(selected)}</span>
                      <span class="icon is-small">
                        <FaChevronDown color="#EB9DA8" size="1.0em" />
                      </span>
                    </button>
                  </div>
                  <div class="dropdown-menu" id="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                      <a href="#" class={`dropdown-item ${selected === 'all' ? 'is-active' : ''}`} onClick={() => handleItemClick('all')} style={{fontSize:`0.6rem`}}>
                        {ShopName('all')}</a>
                      <a href="#" class={`dropdown-item ${selected === 'amazon' ? 'is-active' : ''}`} onClick={() => handleItemClick('amazon')} style={{fontSize:`0.6rem`}}>
                        {ShopName('amazon')}</a>
                      <a href="#" class={`dropdown-item ${selected === 'rakuten' ? 'is-active' : ''}`} onClick={() => handleItemClick('rakuten')} style={{fontSize:`0.6rem`}}>
                        {ShopName('rakuten')}</a>
                      <a href="#" class={`dropdown-item ${selected === 'yahoo' ? 'is-active' : ''}`} onClick={() => handleItemClick('yahoo')} style={{fontSize:`0.6rem`}}>
                        {ShopName('yahoo')}</a>
                      <a href="#" class={`dropdown-item ${selected === 'lohaco' ? 'is-active' : ''}`} onClick={() => handleItemClick('lohaco')} style={{fontSize:`0.6rem`}}>
                        {ShopName('lohaco')}</a>
                      <a href="#" class={`dropdown-item ${selected === 'aupay' ? 'is-active' : ''}`} onClick={() => handleItemClick('aupay')} style={{fontSize:`0.6rem`}}>
                        {ShopName('aupay')}</a>
                      {/* <a href="#" class={`dropdown-item ${selected === 'other' ? 'is-active' : ''}`} onClick={() => handleItemClick('other')} style={{fontSize:`0.6rem`}}>
                        {ShopName('other')}</a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ListSearchAllDrinkResult typeValue={props.typeValue} sizeValue={props.sizeValue} brandValue={props.brandValue} shopValue={props.shopValue} optionValue={props.optionValue} articleUrl={props.articleUrl} articleTitle={props.articleTitle} tempData={tempData}/>
      </>
  )
}
export default ListSearchAllDrink

function BrandActive(props, brand_id){
  let ret = ""
  if(props.brandValue === brand_id){
    ret = "is-active"
  }
  return ret
}

function BrandLink(tempData, props, brand_id){
  let href = '/drink/' + props.typeValue + '_' + props.sizeValue + '_' + brand_id + '_' + props.shopValue + props.optionValue
  return href
}

function BrandExist(tempData, type_id, size_id, brand_id){
  let ret = false
  for(let edge of tempData.allSubbrandsDrinkYaml.edges){
    if(edge.node.brand_id === brand_id){
      for(let type of edge.node.types){
        if(type.type_id === type_id)
        {
          for(let size of type.sizes){
            if(size.size_id === size_id){
              ret = true
            }
          }  
        }
      }  
    }
  }
  return ret
}

function TypeActive(props, type_id){
  let ret = ""
  if(props.typeValue === type_id){
    ret = "is-active"
  }
  return ret
}

function TypeLink(tempData, props, type_id){
  let sizeValue = props.sizeValue
  let brandValue = props.brandValue
  if(!SizeExist(tempData, type_id, props.sizeValue)){
    sizeValue = getFirstSize(tempData, type_id)
  }
  if(!BrandExist(tempData, type_id, sizeValue, brandValue)){
    brandValue = 'all'
  }
  return '/drink/' + type_id + '_' + sizeValue + '_' + brandValue + '_' + props.shopValue + props.optionValue
}

function SizeActive(props, size_id){
  let ret = ""
  if(props.sizeValue === size_id){
    ret = "is-active"
  }
  return ret
}

function SizeLink(tempData, props, size_id){
  let href = '/drink/' + props.typeValue + '_' + size_id + '_' + props.brandValue + '_' + props.shopValue + props.optionValue
  if(!BrandExist(tempData, props.typeValue, size_id, props.brandValue)){
    href = '/drink/' + props.typeValue + '_' + size_id + '_all_' + props.shopValue + props.optionValue
  }
  return href
}

function SizeExist(tempData, type_id, size_id){
  let ret = false
  for(let edge of tempData.allSubbrandsDrinkYaml.edges){
    for(let type of edge.node.types){
      if(type.type_id === type_id)
      {
        for(let size of type.sizes){
          if(size.size_id === size_id){
            ret = true
          }
        }  
      }
    }
  }
  return ret
}

function ShopLink(tempData, props, shop_id){
  let href = '/drink/' + props.typeValue + '_' + props.sizeValue + '_' + props.brandValue + '_' + shop_id + props.optionValue
  return href
}

function getFirstSize(tempData, type_id){
  let ret = ''
  for(let sizes of tempData.allSizesDrinkYaml.edges){
    if((sizes.node.id === 'sss') || (sizes.node.id === 'bbb')){
      continue
    }
    for(let edge of tempData.allSubbrandsDrinkYaml.edges){
      for(let type of edge.node.types){
        if(type.type_id === type_id)
        {
          for(let size of type.sizes){
            if(size.size_id === sizes.node.id){
              ret = size.size_id
              break
            }
          }  
        }
        if(ret !== ''){break}
      }
      if(ret !== ''){break}
    }
    if(ret !== ''){break}
  }
  return ret
}

function getType(tempData, id){
  const ret = tempData.allTypesDrinkYaml.edges.find(edge => {
    return ( edge.node.id === id )
  })
  return ret.node
}

function getSize(tempData, id){
  const ret = tempData.allSizesDrinkYaml.edges.find(edge => {
    return ( edge.node.id === id )
  })
  return ret.node
}

function getBrand(tempData, id){
  const ret = tempData.allBrandsDrinkYaml.edges.find(edge => {
    return ( edge.node.id === id )
  })
  return ret.node
}

function getSubbrand(tempData, id, subid){
  const ret = tempData.allSubbrandsDrinkYaml.edges.find(edge => {
    return ( edge.node.brand_id === id ) && ( edge.node.subbrand_id === subid )
  })
  return ret.node
}

function PriceKanma(_price){
	var price = _price.toString(10);
	var length = price.length;
	var price_kanma;
	if(length > 3)
	{
		price_kanma = price.slice(0,length-3) + "," + price.slice(length-3);
	}
	else
	{
		price_kanma = price;
	}
	return price_kanma;
}